.active-access{
    color: var(--nw-green-dark-color) !important;
}

.filter{
    background-color: #ffffc0;
}

.jstree-search {
    font-style: normal !important;
    color: inherit !important;
    font-weight: normal !important;
    background-color: #ffffc0 !important;
}
.downloader {
  width: 500px;
  min-height: 128px;
  /*position: fixed;
  right: 18px;
  bottom: 18px;
  max-height: 700px;
  overflow-y: auto;
  z-index: 1;*/
}
.downloader .card-header {
  color: #fff;
  background-color: rgba(65, 158, 18, 0.92);
  border-radius: .3rem !important;
}
.downloader .card .list-group {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}
.downloader>.card{
  border-radius: .3rem !important;
}

/*Start Search Compoenent*/
.search-input-table {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.45);
    padding-right: 25px;
    padding-bottom: 4px;
    padding-left: 23px;
    outline: none;
    transition: border-bottom-color 0.3s, border-bottom-width 0.3s;
    position: relative;
  }
  .search-input-table::before {
    transform: translateX(-50%);
    width: 0;
    background-color: var(--nw-blue-color);
    transition: width 0.3s ease-out;
  }
  .search-input-table:hover {
    border-bottom-color: rgba(0, 0, 0, 0.87);
    border-width: 1px;
  }
  .search-input-table:focus {
    border-bottom-color: var(--nw-blue-color);
    border-width: 2px;
  }
  .search-input-table:focus::before {
    width: 100%;
  }
  .search-input-table::placeholder {
    color: #606060;
  }
  /*END Search Compoenent*/
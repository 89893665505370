:root {
    --nw-white-origin-color:#FFFFFF;
    --nw-black-origin-color:#000000;
    --nw-white-dark-color: #dedede;

    --nw-blue-origin-color:#4D8ED0; /*Not yet used*/
    --nw-blue-color:#4483C3; /*#3c77b3;*/
    --nw-blue-light-color:#D9E8F6; /*#e6f2ff;*/
    --nw-blue-light-1-color:#F2F7FC;
    --nw-blue-light-2-color:#89B5E2; /*Not yet used*/
    --nw-blue-dark-color:#2B639C; /*#306090;*/
    --nw-blue-ultra-dark-color:#082441; /*Not yet used*/

    --nw-green-origin-color:#4DD04E; /*Not yet used*/
    --nw-green-light-color:#D9F6D9; /*#e6ffe6;*/
    --nw-green-light-1-color:#F2FCF2; /*#f2fff2;*/
    --nw-green-light-2-color:#89E289; /*#8dd08d;*/
    --nw-green-color:#44C344; /*#3cb33c;*/
    --nw-green-dark-color:#2B9C2C; /*#309030;*/
    --nw-green-ultra-dark-color:#084108; /*Not yet used*/

    --nw-grey-origin-color:#D0D0D0; /*Not yet used*/
    --nw-grey-color:#C3C3C3; /*#808080;*/
    --nw-grey-light-color:#F6F6F6; /*#f7f7f7;*/
    --nw-grey-light-1-color:#FCFCFC; /*Not yet used*/
    --nw-grey-light-2-color:#E2E2E2; /*Not yet used*/
    --nw-grey-very-light-color:#c0c0c0;
    --nw-grey-dark-color:#9C9C9C; /*#888888;*/
    --nw-grey-ultra-dark-color:#9C9C9C; /*Not yet used*/
    --nw-blackgrey-color:#3a3938;
    /*replace variable in the code*/
    --nw-grey-mute-color:#C3C3C3; /*#bfbcbb;*/   /*--nw-grey-color*/
    --nw-grey-mute-light-color:#D0D0D0; /*#d2cfce;*/  /*--nw-grey-origin-color oder --nw-grey-light-2-color*/
    --nw-grey-close-color:#9C9C9C; /*#737170;*/  /*--nw-grey-dark-color*/
    --nw-grey-close-light-color:#C3C3C3; /*#acaaa9;*/   /*--nw-grey-color*/
    --nw-grey-step-color:#E2E2E2; /*#e5e2e1;*/   /*--nw-grey-light-2-color*/
    
    --nw-red-origin-color:#D04E4D; /*Not yet used*/
    --nw-red-color:#C34444; /*#ff0000;*/
    --nw-red-light-color: #F6D9D9; /*#ffe0e0;*/
    --nw-red-light-1-color: #FCF2F2; /*Not yet used*/
    --nw-red-light-2-color: #E28989; /*Not yet used*/
    --nw-red-dark-color:#9C2C2B; /*#c80000;*/
    --nw-red-ultra-dark-color:#414141; /*Not yet used*/

    --nw-orange-origin-color:#D08F4D; /*Not yet used*/
    --nw-orange-color:#C38444; /*#FFA500;*/
    --nw-orange-light-color: #F6E8D9; /*#f8d568;*/
    --nw-orange-light-1-color: #FCF7F2; /*Not yet used*/
    --nw-orange-light-2-color: #E2B689; /*Not yet used*/
    --nw-orange-dark-color:#9C642B; /*#C08000;*/
    --nw-orange-ultra-dark-color:#412408; /*Not yet used*/

    --nw-pink-origin-color:#D04DCF; /*Not yet used*/
    --nw-pink-color:#C344C3; /*Not yet used*/
    --nw-pink-light-color: #F6D9F6; /*Not yet used*/
    --nw-pink-light-1-color: #FCF2FC; /*Not yet used*/
    --nw-pink-light-2-color: #E289E1; /*Not yet used*/
    --nw-pink-dark-color:#9C2B9C; /*Not yet used*/
    --nw-pink-ultra-dark-color:#410841; /*Not yet used*/

    --nw-turquoise-origin-color:#4DCFD0; /*Not yet used*/
    --nw-turquoise-color:#44C3C3; /*Not yet used*/
    --nw-turquoise-light-color: #D9F6F6; /*Not yet used*/
    --nw-turquoise-light-1-color: #F2FCFC; /*Not yet used*/
    --nw-turquoise-light-2-color: #89E1E2; /*Not yet used*/
    --nw-turquoise-dark-color:#2B9C9C; /*Not yet used*/
    --nw-turquoise-ultra-dark-color:#084141; /*Not yet used*/

    --nw-yellow-origin-color:#CFD04D; /*Not yet used*/
    --nw-yellow-color:#C3C344; /*Not yet used*/
    --nw-yellow-light-color: #F6F6D9; /*Not yet used*/
    --nw-yellow-light-1-color: #FCFCF2;
    --nw-yellow-light-2-color: #E1E289;
    --nw-yellow-dark-color:#9C9C2B; /*Not yet used*/
    --nw-yellow-ultra-dark-color:#414108; /*Not yet used*/

    /*RAS */
    --slot-wait-action-color:#F0E68C; /*Not yet used*/
    --ras-new-bg-color:#f3ffe6; /*Not yet used*/
    --ras-wait-bg-color:#ffffe6; /*Not yet used*/
    --ras-link-bg-color:#fff3e6;
}

/* Styles for the overlay */
.overlay {
    min-height: 100%;   /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh; /* These two lines are counted as one :-)       */
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.6);
}

.spinner {
    animation: rotator 1.6s linear infinite;
}
@keyframes rotator {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(270deg);}
}
.path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1.6s ease-in-out infinite;
}
  
@keyframes dash {
    0% {stroke-dashoffset: 187;}
    50% {
        stroke-dashoffset: 46.75;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform: rotate(450deg);
    }
}
  

/* Styles for the loading animation */
/*
.loader {
    border: 8px solid #ffffff;
    border-top: 8px solid var(--nw-blue-color);
    border-radius: 50%;
    width: 200px;
    height: 200px;
    animation: spin 1s linear infinite;
    position: absolute;
    transform: translate(-50%, -50%);
}
*/

/* Keyframes for the loading animation */
/*@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}*/

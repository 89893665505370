@charset "UTF-8";
@import "./variables.css";

/* ------------------------------------------ */
/*START Loading Bar*/
.loading-bar-container {
  background-color: #d1d5db;
  border-radius: 9999px;
  height: 0.5rem;
  position: relative;
  overflow: hidden;
}
.loading-bar {
  background-color: var(--nw-blue-color);
  border-radius: 9999px;
  position: absolute;
  bottom: 0;
  top: 0;
}
.loading-bar-progress {
  width: 50%;
  /* Move the bar infinitely */
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: indeterminate-progress-bar;
}
@keyframes indeterminate-progress-bar {
  from {
      left: -50%;
  }
  to {
      left: 100%;
  }
}
.laoding-bar-finish {
  width: 100%;
}
/*END Loading Bar*/

/*START Override Bootstrap variable*/
:root {
  --bs-primary-rgb: 48, 96, 144;
}
.tooltip.show{
  opacity: 1!important;
}
.tooltip .tooltip-arrow::before {
  --bs-tooltip-bg: #e0e0e0;
}
.nw-tooltip > .tooltip-inner {
  background-color: #f7f7f7 !important;
  color: #000000 !important;
  border: 1px solid #e0e0e0 !important;
}
.nw-tooltip > .tooltip-arrow{
  border-top: 1px solid #e0e0e0 !important;
  color:#000000 !important;
}
.nw-tooltip-dark > .tooltip-inner {
  background-color: rgba(97, 97, 97, 0.92) !important;
  color: #ffffff !important;
  border: 1px solid rgba(97, 97, 97, 0.92) !important;
  border-radius: 4px;
  padding: 4px 8px;
  font-size:  0.6875rem;
  max-width: 800px;
  margin: 2px;
  word-wrap: break-word;
  font-weight: 500;
}
.nw-tooltip-dark > .tooltip-arrow{
  border-top: transparent !important;
  color:transparent !important;
  background:transparent !important;
}
.nw-tooltip-dark .tooltip-arrow::before {
  --bs-tooltip-bg: transparent;
}
/*END Override Bootstrap variable*/
/*START adjust height for scroll access liste*/
.max-height-68{/*Access Right List*/
  max-height: 560px;
  overflow: auto;
}
.max-height-56{/*Access Node link List with description as text*/
  max-height: 417px;
  overflow: auto;
}
/*END adjust height for scroll access liste*/

.px-box{
  padding-left: 40px;
  padding-right: 40px;
}
.cursor-pointer {
  cursor: pointer;
}

/*START Colors*/
.nw-green{
  color: var(--nw-green-color) !important; /*#3cb33c*/
}
.nw-green-light{
  color: var(--nw-green-light-color) !important; /*#e6ffe6*/
}
.nw-green-dark{
  color: var(--nw-green-dark-color) !important; /*#309030*/
}

.nw-blue{
  color: var(--nw-blue-color)/*#3c77b3*/ !important;
}
.nw-blue-light{
  color: var(--nw-blue-light-color) /*#e6f2ff*/ !important;
}
.nw-blue-dark{
  color: var(--nw-blue-dark-color)/*#306090*/ !important;
}

.nw-red{
  color: var(--nw-red-color) !important;
}
.nw-red-dark{
  color: var(--nw-red-dark-color) !important;
}

.nw-orange-dark{
  color: var(--nw-orange-dark-color) !important;
}

.nw-grey-very-light{
  color: var(--nw-grey-very-light-color) !important;
}
.nw-grey{
  color: var(--nw-grey-color) !important;
}
.nw-grey-dark{
  color: var(--nw-grey-dark-color) !important;
}

/*NW Background colors*/
.nw-bg-green-dark{
  background-color: var(--nw-green-dark-color) !important;
  color: #ffffff !important;
}
.nw-bg-green{
  background-color: var(--nw-green-color) !important;
  color: #ffffff !important;
}
.nw-bg-green-light{
  background-color: var(--nw-green-light-color) !important;
  color: #000000 !important;
}
.nw-bg-blue{
  background-color: var(--nw-blue-color) !important;
  color: #ffffff !important;
}
.nw-bg-blue-light{
  background-color: var(--nw-blue-light-color) !important;
  color: #000000 !important;
}
.nw-bg-red{
  background-color: var(--nw-red-dark-color) !important;
  color: #000000 !important;
}

/*NW Border colors*/
.nw-border-green{
  border-color: var(--nw-green-dark-color) !important;
}
/*
.nw-spinner-green{
  color: var(--nw-green-light-color) !important;
}
.nw-spinner-blue{
  color: var(--nw-blue-light-color) !important;
}*/
/*END Colors*/

.nw-rm-btn{
  background: transparent !important;
  border-color: transparent !important;
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%
}

.btn-disabled, .btn-disabled:hover,.nw-select-disabled, .nw-select:disabled, .bm-input:disabled, input:disabled, select:disabled, .disabledElt {
  background-color: var(--nw-grey-light-color);
  border-color: var(--nw-grey-color);
  color: var(--nw-grey-color);
}

.btn-disabled-home {
  background: #ffffff;
  border-color:  var(--nw-grey-color);
  color: var(--nw-grey-color);
}

.btn-disabled-home:hover {
  background: var(--nw-grey-light-color);
  border-color: var(--nw-grey-dark-color);
  color: var(--nw-grey-dark-color);
}

.nw-opacity-6:hover{
  opacity: 0.6;
}

/* START: Clear License select Option hide/show */ 
#toggle-container {
  overflow:hidden;
  padding-right: 2px;
}
.opt{
  margin-top:-100%;
}
.toggle
{
  margin-top:-100%;
  animation-direction:reverse;
  animation:1s 1 alternate forwards close;
}
.toggle.open
{
  animation:1s 1 alternate forwards open;
}

@keyframes open  {
  0% {
     margin-top:-100%;
  }
  
  100% {
     margin-top:0%;
  }

}

@keyframes close  {
  0% {
     margin-top:0%;
  }
  
  100% {
     margin-top:-100%;
  }

}
/* END: Clear License select Option hide/show */

/* Start: Close X btn */
.close-x {
  margin-right: 8px;
  font-size: 1.1rem;
  color: #ffffff  !important;
}
/* END: Close X btn */

.invalid-feedback{
  color: var(--nw-red-color) !important;
  font-size: 13px;
}

.input-error{
  color: var(--nw-red-color) !important;
  font-size: 13px;
  margin-top: .25rem;
}

button, button:focus, button:active, .btn, .btn:focus, .btn:active, input, input:active, input:focus{
  box-shadow: none !important;
}

.sub-section{
  background-color: #e9ecef;
}
.btn{
  padding-left: 3em !important;
  padding-right: 3em !important;
}

input:not([type=checkbox])+label, textarea.bm-input+label{
  opacity: 0.6;
}

hr {
  height: 1px !important;
  /* width:50%;
  margin-left:25% !important;
  margin-right:25% !important; */
}

/* Change Navabar position to adjust the element in the center and give menu-burger icon index 1 */
.navbar-logo-middle{
  position: absolute;
  top: 11.5px;
  left: 0;
  width: 100%;
}
.z-1{
  z-index: 1;
}
.navbar, .dropdown-menu-end{
  background-color: #000000 !important;
}

/*DropDown for language & Auth.*/
.dropdown-menu-end > li> .dropdown-item,
.dropdown-menu-end >.dropdown-item{
  color: #ffffff;
  padding: 0.5rem 1rem !important;
}
.dropdown-menu-end> li> .dropdown-item:not(.nw-unset-link):hover,
.dropdown-menu-end >.dropdown-item:hover{
  color: #ffffff;
  background-color: rgb(84, 84, 84) ;
  cursor: pointer;
}
.nw-unset-link:hover{
	background-color: transparent !important;
}

.menu {
  z-index: 1;
}

.vertical-center{
  min-height: 100%;   /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  padding-top: 5px;
  display: flex;
  align-items: center;
}

.min-vw-100-scrollbar{
  min-width: calc(100vw - (100vw - 100%)); /*correct width if browser scrollbar appear*/
}

.min-vh-100-bar{
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  min-height: calc(100vh - 90px); /*correct height by removin footer & navbar to center element vertically*/
}

/*Remove Browser Scrollbar by showing Modal*/
.modal-dialog{
  min-height: 80%;   /* Fallback for browsers do NOT support vh unit */
  min-height: 80vh; /* These two lines are counted as one :-)       */
}

.logo-name{
  font-size: 16px;
  margin-right: 8px;
}

.modal-backdrop{
  z-index: 1000 !important;
}

.card {
  border-radius: 20px 20px 20px 20px !important;
}

.card-header {
  border-radius: 20px 20px 0 0 !important;
}
.card-body {
  border-radius: 0 0 20px 20px !important;
}

[data-bs-toggle='offcanvas'] {
  border: 0px !important;
}
.offcanvas-start-lg {
    margin-top      : 46px;
    top             : 0;
    left            : 0;
    padding-left    : 0 !important;
    background-color: #000 !important;
    /* padding-left: 30px; */
    width           : 25%;
    /*border-right : 1px solid rgba(255, 255, 255, 0.3);*/
    transform       : translateX(-100%);
}
#close-offcanvas{
  color:#fff !important;
}
.offcanvas-backdrop{
  background-color: transparent !important;
}
@media (max-width : 481px) {
    .offcanvas-start-lg {
        min-width : 100% !important;
        margin-top: 0;
    }
    .navbar-logo{
      width: 120px;
      position: relative;
      top  : auto;
    }
    
    .px-box{
      padding-left: 20px;
      padding-right: 20px;
    }
}
@media (max-width : 991px) and (min-width : 482px) {
    .offcanvas-start-lg {
        min-width: 40% !important;
    }
}

/* FOOTER */
.nw-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #000;
}
@media (max-width : 481px) {
	.nw-footer > a {
		font-size: 12px;
	}
}

/*Flags*/
img.flag{
  border: 1px #404040 solid;
  padding: 0px;
  height: 16px;
  margin-top: -3px !important;
}
img.flags{
    border: 1px #404040 solid;
    padding: 0px;
    height: 16px;
    margin-top: -3px !important;
}
.dropdown-toggle.default-flag::after{
  vertical-align: top !important;
  margin-top: 9px;
}

.fade-in {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
input:focus::placeholder, textarea:focus::placeholder {
  color: #606060;
}
@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.fade-out {
  animation: fadeOut ease 3s;
  -webkit-animation: fadeOut ease 3s;
  -moz-animation: fadeOut ease 3s;
  -o-animation: fadeOut ease 3s;
  -ms-animation: fadeOut ease 3s;
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@keyframes slidebg {
  to {
    background-position:20vw;
  }
}

/* Test Card-Download*/
.nw-rounded-sm{
  border-radius: .3rem !important;
  /*border-bottom: none;*/
}

/*Correct input for desinger*/
input[type="checkbox"]:checked + label::after {
  background: rgba(0, 0, 0, 0);
}

.bar-height{
  height: 100px;
}

/* START FLASH */
.nw-flash{
  margin-top: 46px;
  margin-bottom: 50px;
  position: fixed;
  width: 100%;
  z-index: 1
}
/* END FLASH */

/* START AUETOCOMPLETE */
.autocomplete-container {
  position: relative;
} 
.autocomplete {
  overflow: hidden;
  text-overflow: "";
  white-space: nowrap;
  position: absolute;
  top:13px;
  left: 12px;
  right: 0%;
  padding: 0 4px;
  color: var(--nw-grey-mute-light-color);
  font-weight:lighter !important;
  background-color: transparent;
  pointer-events: none 
}
.autocomplete-container > div > input{
  font-weight:lighter !important;
}
/* END AUETOCOMPLETE */

.svg>svg:hover {
  fill: white;
}.svg>svg {
  fill: var(--nw-green-light-color);
}

.dropdown-licenses::after {
  /*content: none !important;*/
  margin-top: 8px;
  font-size: 24px
}

.node-slected{
  border: 1px #404040 solid;
  padding: 3px;
  background-color: var(--nw-grey-mute-light-color);
}

.li-style { color: darkred; }
.a-style:hover { text-decoration: underline !important; }
p.text-white>a {
  text-decoration: none;
  color: var(--nw-grey-mute-color) !important;
}
p.text-white>a:hover {
  text-decoration: underline;
  color: white !important;
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.glyphicon-upload:before {
  content: "\e027";
}

.icon-rtl {
  background: url('./assets/images/icon/checked.svg') no-repeat right;
  background-size: 20px;
  background-position-x: right 10px;
}

/*Transation size*/
span.nw-trans{
  position: relative;
  transition: all 1s;
  overflow: hidden;
}
span.nw-trans::before {
  content: attr(filesize);
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.25s;
  transform: translateX(0);
}
span.nw-trans-hide::before {
  transform: translateX(-100%);
}

.nav.nav-tabs>.nav-item{
  padding: 0 !important;
  margin: 0 !important;
}
.list-license-count:before{
  transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
}
.list-license-count:after{
  content: url('assets/images/icon/reload-16.svg');
  margin-top: 2px;
  position:absolute;
  opacity:0;
  
  transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
}
.list-license-count:hover:after{
  opacity:1;
}
.list-license-count:hover:before{
  opacity:0;
}

/*nowrap*/
.nowrap{
  overflow: hidden !important;
  text-overflow: "... " !important;
  white-space: nowrap !important;
}

/*START Checkbox switch*/
.switch-checkbox {
  cursor: pointer;
  display: inline-block;
}

.switch-checkbox-custom {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 48px;
  height: 24.8px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.switch-checkbox-custom:before, .switch-checkbox-custom:after {
  content: "";
}
.switch-checkbox-custom:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px var(--nw-grey-light-color);
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.25s;
}
.switch-checkbox:hover .switch-checkbox-custom:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px var(--nw-grey-dark-color);
}
.switch-checkbox-input:checked + .switch-checkbox-custom {
  background: var(--nw-blue-color);
}
.switch-checkbox-input:checked + .switch-checkbox-custom:before {
  left: 28px;
}
.switch-checkbox-input {
  position: absolute;
  display: none !important;
}

.switch-checkbox-label {
  margin-left: 5px;
  position: relative;
}
/*END Checkbox switch*/


/*******START_Font*******/
/* roboto-mono-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Mono 300';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
/* roboto-mono-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Mono 500';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-500.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
/* roboto-mono-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Mono 700';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#RobotoMono') format('svg'); /* Legacy iOS */
}

/* roboto-mono-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'roboto-mono-regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
/* roboto-mono-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'roboto-mono-bold';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-mono-v13-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#RobotoMono') format('svg'); /* Legacy iOS */
}

/* roboto-slab-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Slab 300';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
/* roboto-slab-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Slab 500';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-500.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
/* roboto-slab-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Slab 700';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-slab-v16-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto 300';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto 500';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto 700';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-400 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'roboto-regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'roboto-bold';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* for short texts and captions/label(Beschriftungen) */
.fn-smtxt {
  font-family: "roboto-regular";
}
.fn-smtxt-bold, b {
  font-family: "roboto-bold";
}
.fn-smtxt-bolder {
  font-family: "roboto-bold";
}

/* for continuous text(Fließtexte) */
.fn-txt {
  font-family: "roboto-regular";
}
.fn-txt-bold {
  font-family: "roboto-bold";
}
.fn-txt-bolder {
  font-family: "roboto-bold";
}

/* for code input and output. */
/*.fn-code { // updated from Designer
  font-family: "roboto-mono-regular";
}*/
.fn-code-bold {
  font-family: "roboto-mono-bold";
}
.fn-code-bolder {
  font-family: "roboto-mono-bold";
}
/*******END_Font*******/

/*******START_Extern_Designer*******/

/* Grundlegende Eigenschaften */
:focus { outline: none }

.nw-body-container{
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

@media screen and (min-width: 1100px) {
  .nw-body-container {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .nw-body-container {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
}
@media screen and (min-width: 1450px) {
  .nw-body-container {
    padding-right: 265px !important;
    padding-left: 265px !important;
  }
}

body {
    background: var(--nw-grey-light-color);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

body, p, h1, h2, h3, h4, h5, h6, .nw-color-normal {
    color: var(--nw-blackgrey-color);
}

h1, h2, h3, h4, h5, h6, .nw-bold {
    font-family: 'roboto-bold', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
}

body, p, .fn-code {
    font-family: 'roboto-regular', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
}

h1 {
    font-size: 2rem;
    margin-top: 0.5em;
    margin-bottom: 35px;

}

h2 {
    font-size: 1.3rem;
}

h3 {
    font-size: 1.1rem;
}

h4 {
    font-size: 1rem;
}

@media (max-width : 481px) { 
    h1 {
        font-size: 1.7rem;

    }
    h2 {
        font-size: 1.2rem;
    }
}

p {
    font-size: 1rem;
}

.bm-p-mute {
    font-size: .8rem;
    opacity: .5;
    color: var(--nw-blackgrey-color);
    text-decoration: none;
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: none;
}

.bm-clearer {
    clear: both;
}

.bm-display-none {
    display: none;
}

.bm-main {
    width: calc(100% - 20px);
    max-width: 700px;
    margin: 60px auto 35px auto;
}

.bm-margin-auto {
    margin-left: auto;
    margin-right: auto;
}

.bm-align-center {
    text-align: center;
}

.bm-align-right {
    text-align: right;
}

.bm-marg-top-10 {
    margin-top: 10px;
}

.bm-max-width-200 {
    max-width: 200px;
}

.bm-max-width-300 {
    max-width: 300px;
}

.bm-max-width-400 {
    max-width: 400px;
}

.bm-max-width-450 {
    max-width: 450px;
}

.bm-max-width-500 {
    max-width: 500px;
}

.bm-bg-green, .bm-bg-green p {
    background: var(--nw-blue-color);
    color: #ffffff;
}

/* Buttons */
button {
    border-radius: .3rem;
    border: 1px solid var(--nw-blackgrey-color);
    background: var(--nw-blackgrey-color);
    padding: 10px 25px;
    transition: background 200ms ease-out, border 200ms ease-out, color 300ms ease-out;
}

button:hover, button:active {
    transition: background 300ms ease-out, border 300ms ease-out, color 300ms ease-out;
}

.nw-btn-greyBlue{
    background: var(--nw-grey-color);
    border-color: var(--nw-grey-color);
    color: #fff;
}
.nw-btn-greyBlue:hover, .nw-btn-greyBlue:active {
    background: var(--nw-blue-dark-color);
    border-color: var(--nw-blue-dark-color);
}

.bm-btn-blue {
    background: var(--nw-blue-color);
    border-color: var(--nw-blue-color);
    color: #fff;
}

.nw-overlay-btn-grey {
    background: transparent;
    border-color:  #ffffff;
	  color: #ffffff;
}

.nw-overlay-btn-grey:hover, .nw-overlay-btn-grey:active {
    background: var(--nw-grey-color);
}

.bm-btn-blue:hover, .bm-btn-blue:active {
    background: var(--nw-blue-dark-color);
    border-color: var(--nw-blue-dark-color);
}

.bm-btn-grey {
    background: var(--nw-grey-color);
    border-color: var(--nw-grey-color);
}

.bm-btn-grey:hover, .bm-btn-grey:active {
    background: var(--nw-grey-dark-color);
    border-color: var(--nw-grey-dark-color);
}

.bm-btn-red {
    background: var(--nw-red-color);
    border-color: var(--nw-red-color);
    color: #fff;
}

.bm-btn-red:hover, .bm-btn-red:active {
    background: var(--nw-red-dark-color);
    border-color: var(--nw-red-dark-color);
}

.bm-btn-rm {
    background: var(--nw-grey-color);
    border-color: var(--nw-grey-color);
    color: #fff;
}

.bm-btn-rm:hover, .bm-btn-rm:active {
    background: var(--nw-red-color);
    border-color: var(--nw-red-color);
}

.bm-btn-green {
    background: var(--nw-green-color);
    border-color: var(--nw-green-color);
    color: #fff;
}

.bm-btn-green:hover, .bm-btn-green:active {
    background: var(--nw-green-dark-color);
    border-color: var(--nw-green-dark-color);
}

.bm-btn-green-out {
    background: #ffffff;
    border-color: var(--nw-green-dark-color);
    color: var(--nw-green-dark-color);
}

.bm-btn-green-out:not(.fix):hover, .bm-btn-green-out:not(.fix):active {
    background: var(--nw-green-dark-color);
    border-color: var(--nw-green-dark-color);
    color: #ffffff;
}

.bm-btn-blue-out {
    background: #ffffff;
    border-color: var(--nw-blue-dark-color);
    color: var(--nw-blue-dark-color);
}

.bm-btn-blue-out:not(.fix):hover, .bm-btn-blue-out:not(.fix):active {
    background: var(--nw-blue-dark-color);
    border-color: var(--nw-blue-dark-color);
    color: #ffffff;
}

.bm-btn-grey-out {
    background: #ffffff;
    border-color: var(--nw-grey-dark-color);
    color: var(--nw-grey-dark-color);
}

.bm-btn-grey-out:not(.fix):hover, .bm-btn-grey-out:not(.fix):active {
    background: var(--nw-grey-dark-color);
    border-color: var(--nw-grey-dark-color);
    color: #ffffff;
}

.bm-btn-white {
    background: #ffffff;
    border-color: #ffffff;
    color: var(--nw-blackgrey-color);
}

.bm-btn-white:hover, .bm-btn-white:active {
    background: var(--nw-white-dark-color);
    border-color: var(--nw-white-dark-color);
}

.bm-btn-mute {
    background: transparent;
    border-color: var(--nw-grey-mute-light-color);
    color: var(--nw-grey-mute-color);
}

.bm-btn-mute:hover, .bm-btn-mute:active {
    background: var(--nw-grey-mute-light-color);
    border-color: var(--nw-grey-mute-light-color);
    color: #fff;
}

.bm-btn-card-prev {
    float: left;
}

.bm-btn-card-next {
    float: right;
}

.bm-text-danger {
    color: var(--nw-red-color);
}

.bm-text-success {
    color: var(--nw-green-color);
}

/* Trenner & Abstände */
h1+.bm-sep-1 {
    margin-top: 1px;
}
.bm-sep-1 {
    margin-top: 35px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--nw-grey-step-color);
    padding: 0 0 10px 0;
}

.bm-sep-2 {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--nw-grey-step-color);
    opacity: 1;
}

.bm-acc-header {
    width: 100%;
}

.bm-opt-img-1 {
    margin-left: 10px;
}

.bm-btn-marg {
    margin: 5px;
}

.bm-marg-top-10 {
    margin-top: 10px;
}

.bm-marg-top-30 {
    margin-top: 30px;
}

.bm-marg-right-10 {
    margin-right: 10px;
}

.bm-marg-bott-1rem {
    margin-bottom: 1rem;
}

/* Karte */
.bm-card {
    width: 100%;
    max-width: 700px;
    border-radius: .3rem;
    background: #ffffff;
    padding: 15px 20px 20px 20px;
    margin: 0 auto;
    position: relative;
    border: 0;
}
 
.bm-card-centered {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: bm-box-absolute 700ms ease-out;
} 

@keyframes bm-box-absolute {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    
    30% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
    
.bm-card-header {
    margin: 50px 0 65px 0;
}    
    
.bm-card-header_small {
    margin: 22px 0 25px 0;
}
  
.bm-close-x {
    position: absolute;
    right: 25px;
    top: 25px;
    line-height: 1rem;
    font-size: 1.4rem;
    text-decoration: none;
    color: var(--nw-grey-close-light-color);
    transition: color 300ms ease-out;
    cursor: pointer;
}

.bm-close-x:hover, .bm-close-x:active {
    color:  var(--nw-grey-close-color);
    transition: color 300ms ease-out;
}

.bm-close-x-white, .bm-close-x-white:hover, .bm-close-x-white:active {
    color: #ffffff;
}

.bm-nav-card-bottom {
    margin-top: 35px;
}

.bm-note-nav-card-bottom {
    text-align: right;
    display: block;
    margin-top: 20px;
}

.bm-note-nav-card-bottom span {
    margin-left: 5px;
    display: inline-block;
    font-family: 'roboto-bold', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
}

/* Update Window */
.bm-update-wrapper {
    margin-bottom: 20px;
    border-radius: .3rem;
    width: 100%;
    position: relative;
    padding: 60px 20px 20px 20px;
}

.bm-update-wrapper .bm-two-columns-right:last-child { 
    margin-bottom: 0px;
}

/* Columns */
.bm-two-columns {  
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
    "two-columns-left" "two-columns-right";
}

.bm-two-columns-left {
    grid-area: two-columns-left;
}

.bm-two-columns-right { 
    grid-area: two-columns-right;
    margin-bottom: 15px;
}

.bm-counter-1 {
    display: none;
}

/* Input */

 ::-webkit-input-placeholder {
    /* WebKit browsers */
     color: transparent;
}
 :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
     color: transparent;
}
 ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
     color: transparent;
}
 :-ms-input-placeholder {
    /* Internet Explorer 10+ */
     color: transparent;
}
 input::placeholder {
     color: transparent;
}
 textarea::-webkit-input-placeholder {
    /* WebKit browsers */
     color: transparent;
}
 textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
     color: transparent;
}
 textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
     color: transparent;
}
 textarea:-ms-input-placeholder {
    /* Internet Explorer 10+ */
     color: transparent;
}
 textarea::placeholder {
     color: transparent;
}

.bm-input-wrapper, .div-input-wrapper {
    position: relative;
    margin-bottom: 1rem;
}

.bm-input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid var(--nw-grey-mute-light-color);
    border-radius: .3rem;
    transition: all 150ms ease-in-out;
    box-shadow: none;
    outline: none;
    background: transparent;
}

.bm-input:focus {
    border-color: var(--nw-blue-color);
    transition: all 150ms ease-in-out;
    box-shadow: none;
    outline: none;
}

.bm-input+label {
    position: absolute;
    top: 12px;
    left: 12px;
    transform: scale(1);
    padding: 2px 5px;
    border-radius: .2rem;
    opacity: 0;
    transform-origin: left;
    pointer-events: none;
}

.bm-input:focus+label {
    top: -13px;
    color: var(--nw-blue-color);
    background: #ffffff;
    padding: 2px 5px;
    opacity: 1;
    transform: scale(0.7);
}

.div-input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid var(--nw-green-light-2-color);
    border-radius: .3rem;
    box-shadow: none;
    outline: none;
    background: transparent;
    background-color: var(--nw-green-light-1-color);
    color: var(--nw-grey-color);
}


.div-input+label {
    top: -13px;
    left: 10px !important;
    color: var(--nw-grey-color) /*var(--nw-blue-color)*/;
    background: var(--nw-green-light-color);
    border-radius: .2rem;
    padding: 2px 5px;
    opacity: 1;
    font-size: 10px;
    position: absolute;
}

/*START Adapt select input to input design*/
.nw-select{
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid var(--nw-grey-mute-light-color);
    border-radius: .3rem;
    transition: all 150ms ease-in-out;
    box-shadow: none;
    outline: none;
    background-color: #ffffff ;
}

.nw-select:focus {
    border-color: var(--nw-blue-color);
    transition: all 150ms ease-in-out;
    box-shadow: none;
    outline: none;
}
.nw-select+label {
    position: absolute;
    top: 12px;
    left: 12px;
    transform: scale(1);
    padding: 2px 5px;
    border-radius: .2rem;
    opacity: 0;
    transform-origin: left;
    pointer-events: none;
}
option[value="disabled"]:disabled {
    color: var(--nw-grey-mute-light-color);
}
.nw-select:focus+label {
    top: -13px;
    color: var(--nw-blue-color) !important;
    background: #ffffff;
    padding: 2px 5px;
    opacity: 1;
    transform: scale(0.7);
}
/*END Adapt select input to input design*/

.bm-input-wrapper>.bm-input:not(:placeholder-shown):focus~label {
    color: var(--nw-blue-color);
}
/*Show label only for disabled select = .bm-input-wrapper>.nw-select:not(:placeholder-shown)~label*/
.bm-input-wrapper>.bm-input:not(:placeholder-shown)~label,.bm-input-wrapper>.nw-select:not(:placeholder-shown)~label {
    top: -13px;
    color: var(--nw-grey-mute-light-color);
    background: #ffffff;
    opacity: 1;
    transform: scale(0.7);
    transition: all 150ms ease-in-out;
}
.bm-input-is-invalid {
    border-color: var(--nw-red-color);
} 

.bm-input-is-invalid~.invalid-feedback, .bm-input-is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
    display: block;
}

.input-small {
  all: unset;
  width: calc(100% - 20px);
  background: #ffffff;
  border: 1px solid var(--nw-grey-close-light-color);
  border-radius: .3rem;
  padding: 1px 10px;
  margin-bottom: 4px;
  background-size: 12px;
}
.input-small::placeholder{
    color: unset;
}

/*START PHONE_INPUT */
.phoneInput::placeholder{
    color: var(--nw-grey-dark-color);
}
.phoneInput:focus::placeholder{
    color: var(--nw-grey-close-light-color);
}
.phoneInput{
    height: 48px !important;
    width: 100% !important;
}
.phoneInput:disabled{
    background-color: var(--nw-grey-light-color) !important;
    border-color: var(--nw-grey-color) !important;
    color: var(--nw-grey-color) !important;
    cursor: revert !important;
}
.phoneInput:hover{
    border-color: var(--nw-grey-mute-light-color) !important;
}
.phoneInput:hover:focus{
    border-color: var(--nw-blue-color) !important;
}
.react-tel-input>div.special-label:focus{
    color: var(--nw-blue-color) !important;
}
.phone-label{
    position: absolute;
    z-index: 1;
    padding: 2px 5px;
    color: var(--nw-grey-mute-light-color);
    top: -13px !important;
    left: -8px !important;
    background: #ffffff;
    opacity: 1;
    transform: scale(0.7);
    transition: all 150ms ease-in-out;
}
.react-date-picker__calendar--open{
    z-index: 2 !important;
}
.country-list{
    margin-top: 1px !important;
}
/*END PHONE_INPUT */

select {
    /*all: unset;*/ /* Since 06.2024 OnFocus selected text will be selected when mouse hovers over it*/
    appearance: none; /* Remove default arrow in modern browsers */
    -moz-appearance: none; /* Remove default arrow in Firefox */
    -webkit-appearance: none; /* Remove default arrow in Safari */
    width: 100%; /*calc(100% - 20px);*/
    background: #ffffff;
    /*background: var(--nw-grey-step-color)#;*/
    background-image: url('assets/images/icon/arrow.down.svg');
    border: 1px solid var(--nw-grey-close-light-color);
    border-radius: .3rem;
    padding: 1px 10px;
    margin-bottom: 4px;
    position: relative;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: right 7px center;
}

select:focus, .border-focus:focus {
    border-color: var(--nw-blue-color);
}

select:disabled {
    background-image: url('assets/images/icon/arrow.down.disable.svg');
    border-radius: .3rem;
    position: relative;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: right 7px center;
}
/*
select:disabled:hover {
    background-color: var(--nw-grey-color);
    background-image: url('assets/images/icon/arrow.down.svg');
    border: 1px solid var(--nw-grey-color);
    border-radius: .3rem;
    position: relative;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: right 7px center;
}*/
/*
select:disabled {
    background-image: url('assets/images/icon/arrow.down.svg');
    border-radius: .3rem;
    position: relative;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: right 7px center;
}*/


input[type="checkbox"] {
    all: unset;
    width: 20px;
    height: 20px;
    border-radius: .2rem;
    background: transparent;
    border: 1px solid var(--nw-grey-close-light-color);
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

input[type="checkbox"]:checked {
    background-color: var(--nw-blue-color);
    background-image: url('assets/images/icon/bm-check.svg');
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
}
input[type="checkbox"]:disabled {
    background-color: var(--nw-grey-color);
    border-color: var(--nw-grey-dark-color);
    color: var(--nw-grey-color);
}

input[type="checkbox"]:checked:disabled {
  background-color: var(--nw-grey-very-light-color) !important;
  border-color: var(--nw-blackgrey-color) !important; 
}

input[type="checkbox"].red:checked {
    background-color: var(--nw-red-color);
}

/* Modal */
.bm-register-checklist {
    margin-bottom: 10px;
}

.bm-register-checklist svg {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 9px;
}

.bm-register-checklist li, .bm-register-checklist span {
    margin: 0;
    padding: 0;
    position: relative;
}

.bm-register-checklist span {
    padding-left: 20px;
}

.bm-signup-img {
    width: 80%;
    max-width: 120px;
    text-align: center;
    margin: 20px auto 40px;
}

.bm-lang-img-modal {
    margin: 20px 10px;
    text-align: center;
}

.bm-lang-img-modal img {
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 5px;
}

@media (min-width: 450px) {

    .bm-counter-1 {
        display: block;
        float: right;
        padding: 0;
        margin: 0 15px 0 0;
        line-height: 3rem;
        font-size: 0.8rem;
        color: var(--nw-grey-close-light-color);
    }
}

@media (min-width: 700px) {

    .bm-main {
        margin: 60px auto 55px auto;
    }
    
    /* Buttons */
    
    .bm-two-columns-left button, .bm-two-columns-right button {
        width: 100%;
    }
    
    /* Karte und Update */

    .bm-card {
        padding: 15px 40px 35px 40px;
    }

    .bm-update-wrapper {
        padding: 60px 40px 35px 40px;
    }

    .bm-update-wrapper p:last-of-type {
        margin-bottom: 0;
    }
    
    /* Columns */
    
    .bm-two-columns {  
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0px 20px;
        grid-auto-flow: row;
        grid-template-areas:
        "two-columns-left two-columns-right";
    }

    .bm-two-columns-left {
        grid-area: two-columns-left;
    }

    .bm-two-columns-right { 
        grid-area: two-columns-right;
        margin-bottom: 0;
    }

}

@media (min-width: 700px) and (min-height: 900px) {
    
    /*.bm-box-absolute {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: bm-box-absolute 700ms ease-out;
    } */
    
    @keyframes bm-box-absolute {
        0% {
            transform: translate(-50%, -50%) scale(0.8);
            opacity: 0;
        }
        
        30% {
            transform: translate(-50%, -50%) scale(0.8);
            opacity: 0;
        }
        
        100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
    }

}

/* START Tree Icons */
.tree-icon {
  width: 20px !important;
  height: 20px !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.tree-icon-user {
  background-image: url('assets/images/icon/tree/tree-user.svg') !important;
}

.tree-icon-users {
  background-image: url('assets/images/icon/tree/tree-users.svg') !important;
  width: 23px !important;
  height: 23px !important;
}

.tree-icon-layers {
  background-image: url('assets/images/icon/tree/tree-layers.svg') !important;
}

.tree-icon-access {
  background-image: url('assets/images/icon/tree/tree-circle-checked.svg') !important;
}

.tree-icon-root {
  background-image: url('assets/images/icon/tree/tree-folder.svg') !important;
}
/* END Tree Icons */

/*******END_Extern_Designer*******/


/******************START Material Table**********************/
/*column title align left*/
/*hide filter input*/
/*.MuiToolbar-root>.MuiFormControl-root.MuiTextField-root{
  display: none;
}*/
/*[data-testid="CheckBoxOutlineBlankIcon"], [data-testid="CheckBoxIcon"] {
    background-color: transparent;
    position: absolute;
    margin-right: 10px;
    z-index: -10;
}
[data-indeterminate]{
    margin-right: 20px !important;
}*/
.checkAllList+svg{
    position: absolute !important;
    display: none;
    pointer-events: none;
}

.bm-btn-green-action {
  background: var(--nw-green-color);
  border-color: var(--nw-green-color);
  color: #fff;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.bm-dropDown-action {
  color: #fff;
  padding: 5px 10px; 
  cursor: pointer;
}
.bm-disabled-action {
  background: var(--nw-grey-color);
  border-color: var(--nw-grey-color);
}
.bm-enabled-action {
  background: var(--nw-blue-color);
  border-color: var(--nw-blue-color);
}
.bm-enabled-action:hover {
  background: var(--nw-blue-dark-color);
  border-color: var(--nw-blue-dark-color);
}

.bm-btn-green-action:hover, .bm-btn-green-action:active {
  background: var(--nw-green-dark-color);
}
/*Search*/
span[data-testid="material-table-column-sort-order-indicator"] {
  width: 0 !important;
}
.license-action{
  float: right;
}
/*Change actions order*/
.bg-table-icon{
  /* display: -webkit-inline-box; */
  /* display: -webkit-inline-flex; */
  /* display: -ms-inline-flexbox; */
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  /* border-radius: 0; */
  /* padding: 0; */
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  /* color: inherit; */
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
/*Remove hover on not Free action buttons*/
td>div>button.MuiIconButton-root:hover{
  background-color: transparent !important;
}
/*Column hide/show change order to put it right*/
div.MuiBox-root.css-p9qzma :first-child{
  order: 1 !important; 
}
.MuiInput-underline:not(.MuiInputBase-adornedStart.MuiInputBase-adornedEnd){ /*Edit Input fields*/
  max-width:max-content !important;
}
/*Head*/
.MuiTableCell-head{
  text-align: left !important;
  padding: 19px 3px 19px 3px !important;
}
.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-paddingCheckbox{
  background-color: var(--nw-blue-light-color) !important;
  padding-top: 15px;
  padding-right: 10px;
}
.MuiTableCell-head-{
  font-size: 1rem !important;
}
/*Body*/
td.MuiTableCell-root.MuiTableCell-body:not(.MuiTableCell-paddingNone){
  padding-left: 3px;
  padding-right: 3px;
}
/*Fix Action column width*/
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-paddingCheckbox{
  width: 10% !important;
}
/*Change pagination color*/
.MuiTableFooter-root > .MuiTableRow-root > .MuiTableCell-footer{
    background-color: var(--nw-blue-light-color) !important;
}
/*Adjust Action button in the middle*/
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeMedium {
    font-size: 1rem !important;
}
/*Paination*/
.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{
    margin-top: auto;
}
/*Pagination*/
footer{
    z-index: 100;
}
td.MuiTableCell-root.MuiTableCell-body>div>button:hover,.bg-transparent:hover{
    opacity:0.5;
}
.MuiIconButton-root:hover{
    background-color: #ffffff !important;
}
.MuiCheckbox-root>span>.MuiSvgIcon-root{
    fill: var(--nw-blue-color) !important;
}
ul>li>label>span>input[id^="column-toggle"]{
    display: none;
}
table.MuiTable-root>thead.MuiTableHead-root{
    background-color: var(--nw-blue-light-color) !important;
}
/*rows padding & hover color*/
td.MuiTableCell-root:not(.MuiTableCell-paddingNone){
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
tbody.MuiTableBody-root>tr.MuiTableRow-root:hover{
  background: var(--nw-grey-light-color);
}
@media screen and (max-width: 800px){
    /*Free Actions*/
    div.MuiBox-root>div>div, div.MuiBox-root>div>div>span>div{
      display: block  !important;
    }
    .license-action{
      float:unset;
    }
    /*free actions: filter add button ...*/
    div.nw-toolBar>div{
      width: 100% !important;
      display:block !important;
      padding: 10px 0 10px 10px;
    }
    div.MuiBox-root.css-p9qzma>div>div{
        justify-content: center; /* Center along the main axis (horizontally) */
        align-items: center; /* Center along the cross axis (vertically) */
    }
    /*change free action svg size*/
    /*div.MuiBox-root.css-p9qzma>div>div svg, button.MuiButtonBase-root>div.dropdown>span.dropdown-toggle{
        width: 48px;
        height: 48px;
    }*/
    /*action column*/
    .MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-paddingCheckbox{
        text-align: left !important;
        margin-left: 16px;
    }
    .MuiTableCell-root, .MuiTableRow-root{
        width: 100% !important;
        display:block !important;
        box-sizing:border-box;
        clear:both
    }
    tr > td.MuiTableCell-root.MuiTableCell-body {
        border: none;
    }

    tr.MuiTableRow-root {
        border-bottom: 1px solid black;
    }
    /*pagination*/
    svg[data-hide="small"]{
        display: none;
    }

    .MuiTablePagination-displayedRows,.MuiTablePagination-selectLabel{
        display: none !important;
    }
    .MuiTablePagination-toolbar>.MuiBox-root,
    .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar,
    .MuiInputBase-root.MuiInputBase-colorPrimary{
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
   
    /*Description*/
    .MuiTypography-root.MuiTypography-h6{
        display: none;
    }
}
/*merge tabs border with content border*/
.tab-content{
    border-right: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6 ;
    border-bottom: 1px solid #dee2e6;
    padding: 0px !important;
}
/*tabs all width*/
.tabs>.container{
    padding: 15px 0 15px 0;
    min-width: 100%;
}
/*END TABS*/

/*START GROUPING DRAGDROP*/
.css-1mouena {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
}
/*END GROUPING DRAGDROP*/

/*START Filter Field*/
.MuiTextField-root{
    min-width: 300px !important;
}
/*END Filter Field*/

/*START RAS LIST*/
select option[value="new"]:hover {
    color: var(--nw-green-dark-color) !important;
}
option:hover{
    background: #dee2e6;
}
ul>li>label>span>span>input[id^="column-toggle"]{
    display: none;
}
/* Table Description Remove ellipsis */
div.MuiBox-root>h6{
    white-space: initial !important;
    text-align: left !important;
}
/*END RAS LIST*/
/****************END Material Table*******************/

div.parentCopyText:hover .copyText {
  display: block;
}

/********************START Loader-Element***************/
.loader-elt {
  /*Loading circle*/
  /*border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 24px;
  height: 24px;*/
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.inline-div {
  display: inline;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/********************END Loader-Element***************/

/****************START Date-Picker License*******************/
.react-date-picker__wrapper{
  border: none !important;
}
.react-date-picker.sellDatePanel{
    width: 100%;
}
.react-date-picker.sellDatePanel>div>div{
    margin-left: 11px;
}
/****************END Date-Picker*******************/

/*Start Enter select Input Email ESD*/
.EnterSelectEmail__value-container, .EnterSelectEmail__indicator, .EnterSelectEmail__indicators, .EnterSelectEmail__input-container{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 8px !important;
  padding-right: 0px !important;
  margin-top: -0.3px !important;
}
div.EnterSelectEmail__control{
  margin-left: -2px;
}
.EnterSelectEmail__input-container>input{
  margin-left: -8px !important;
}
.EnterSelectEmail__control, .EnterSelectEmail__control:active, .EnterSelectEmail__control:focus{
  border-color: var(--nw-grey-close-light-color) !important;
  margin-bottom: 4px;
}
.EnterSelectEmail__indicator-separator{
  display: none !important;
}
.EnterSelectEmail__indicator{
  padding-right: 7px !important;
}
.arrowDownIcon{
  width: 12px;
  height: 12px;
  background-image: url('assets/images/icon/arrow.down.svg');
}
div.EnterSelectEmail__control--is-disabled{
  background-color: var(--nw-grey-light-color) !important;
  border-color: var(--nw-grey-color) !important;
  color: var(--nw-grey-color) !important;
}
/*Fix List*/
div.EnterSelectEmail__menu {
  margin-top: 0px;
  margin-left: -2px;
}
div.EnterSelectEmail__menu>.EnterSelectEmail__menu-list {
  padding: 3px;
}
div.EnterSelectEmail__menu>.EnterSelectEmail__menu-list>* {
  padding: 2px;
}
/*END Enter select Input Email ESD*/

select>option {
  background-color: white !important/* your preferred background color */;
}
.inputDropdown-list {
    background: #ffffff;
    border: 1px solid var(--nw-grey-close-light-color);
    border-radius: .3rem;
}
.input-dd-bg:hover, .input-dd-bg:active {
    background: var(--nw-grey-mute-light-color);
    cursor: pointer;
    border: 1px solid var(--nw-grey-mute-light-color);
    border-radius: .2rem;
}
.input-dd-bg{
    border: 1px solid white;
    padding-top: 3px;
    padding-bottom: 3px;
}